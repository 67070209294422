import React, { useEffect, useState } from 'react';

const WorkContext = React.createContext({
  works: null,
  worksToDisplay: null,
  directors: null,
  isDirectorListing: false,
  currentDirector: null,
  theme: 'dark',
  showDirectorScreen: false,
  showContactScreen: false,
  showArchiveScreen: false,
  updateWork: () => {},
  updateDirectors: () => {},
  updateIsDirectorListing: () => {},
  updateCurrentDirector: () => {},
  updateShowDirectorScreen: () => {},
  updateShowContactScreen: () => {},
  toggleTheme: () => {},
});

const WorkProvider = ({ children }) => {
  const [works, setWorks] = useState(null);
  const [worksToDisplay, setWorksToDisplay] = useState(null);
  const [directors, setDirectors] = useState(null);
  const [isDirectorListing, setIsDirectorListing] = useState(false);
  const [currentDirector, setCurrentDirector] = useState(null);
  const [showDirectorScreen, setShowDirectorScreen] = useState(false);
  const [showContactScreen, setShowContactScreen] = useState(false);
  const [showArchiveScreen, setShowArchiveScreen] = useState(false);

  const userTheme = typeof window !== 'undefined' && window.__theme
  const [theme, setTheme] = useState(userTheme);

  useEffect(() => {
    window.__setPreferredTheme(theme)
    window.__onThemeChange = () => setTheme(window.__theme);
  })

  return (
    <WorkContext.Provider value={{ 
      works,
      worksToDisplay,
      directors,
      isDirectorListing,
      currentDirector,
      showContactScreen,
      showDirectorScreen,
      showArchiveScreen,
      theme,
      updateWork: (newWorks) => {
        setWorks(newWorks);
      },
      updateWorksToDisplay: (newWorksToDisplay) => {
        setWorksToDisplay(newWorksToDisplay);
      },
      updateDirectors: (newDirectors) => {
        setDirectors(newDirectors);
      },
      updateIsDirectorListing: (newIsDirectorListing) => {
        setIsDirectorListing(newIsDirectorListing);
      },
      updateCurrentDirector: (newCurrentDirector) => {
        setCurrentDirector(newCurrentDirector);
      },
      updateShowDirectorScreen: (newShowDirectorScreen) => {
        setShowDirectorScreen(newShowDirectorScreen);
      },
      updateShowContactScreen: (newShowContactScreen) => {
        setShowContactScreen(newShowContactScreen);
      },
      updateShowArchiveScreen: (newShowArchiveScreen) => {
        setShowArchiveScreen(newShowArchiveScreen);
      },
      toggleTheme: () => {
        theme === 'dark' ? setTheme('light') : setTheme('dark');
      },
     }}>
      {children}
    </WorkContext.Provider>
  )
};

export default WorkContext;

export { WorkProvider };
